<template>
    <div class="library-layout">
        <topWelcomeBanner style="position: relative; top: 60px; width: 100%;  z-index: 1;"></topWelcomeBanner>
        <slot></slot>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";

export default {
    name: "libraryLayout",
    components: {
        topWelcomeBanner
    }
}
</script>